import * as React from "react"
import { graphql } from 'gatsby'

import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import Seo from "../components/seo"
import ListingTilesSection from '../components/listingTilesSection'
import Layout from "../components/layout"

import "./kariera.css"

const KarieraPage = ({data, location}) => {

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Kariera" data={data.header} />
      </AnimationWrapper>

      {data.page.frontmatter.text_sections[0] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="kariera-description description-medium" data={data.page.frontmatter.text_sections[0]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="kariera-tiles-section" className="listing-tiles-section">
        <ListingTilesSection data={data.karieraTiles} subcategoryName="Aktualnie poszukujemy:" location="kariera" />
      </section>

      {data.page.frontmatter.text_sections[1] &&
        <Container className="first-section">
          <AnimationWrapper>
            <TextSection className="kariera-description kariera-last" data={data.page.frontmatter.text_sections[1]} />
          </AnimationWrapper>
        </Container>
      }

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kariera" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_sections{
          title
          title_visible
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          text
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/header-kariera.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 95
            transformOptions: {fit: COVER}
            webpOptions: {quality: 95}
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    karieraTiles: allMdx(
        sort: { fields: [frontmatter___order], order: ASC }
        filter: {frontmatter: {type: {eq: "kariera-listing"}}}
      ){
        nodes {
          frontmatter {
            title
            # subtitle
            # desc_short
          }
          id
          body
          internal {
            contentFilePath
          }
        }
      }
  }
`

export default KarieraPage
