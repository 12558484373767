import * as React from "react"
import { Link } from "gatsby"
// import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from "gatsby-plugin-image"
// import { generateMedia } from 'styled-media-query'

import "./header.css"

const Header = ({ data, pageName, location, overlay }) => {

  const hasOverlay = overlay === true ? '' : 'no-overlay';

  return (

    <div className={`page-header ${location}-header ${hasOverlay}`}>

      <div className="header-info-container">
        <h1 className="page-title">{pageName}</h1>
        <ul id="breadcrumbs">
          <li><Link to="/">Transpol</Link></li>
          <li className="active">{pageName}</li>
        </ul>
      </div>

      <div className="header-img-container">
        <GatsbyImage
          image={data.childImageSharp.gatsbyImageData}
          alt={`${pageName}-header`}
        />
      </div>

    </div>

  )
}

Header.defaultProps = {
  overlay: true,
};

export default Header