import * as React from "react"

import ListingTile from "./listingTile"
import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import Title from "./title"

const ListingTilesSection = ( {data, location, subcategoryName} ) => {

    return (
      <Container>
        {subcategoryName &&
          <AnimationWrapper className="subcategory-name-wrapper" animationType="fadeInUp">
            <Title className="subcategory-name">{subcategoryName}</Title>
          </AnimationWrapper>
        }
        <div className={`${location}-tiles listing-tiles-section`}>
          {data.nodes.map((node, key) => (
            <ListingTile key={key} listing={node} location={location} />
          ))}
        </div>
      </Container>
    )
  }

export default ListingTilesSection