import * as React from "react"

import CustomAniLink from "../components/customAniLink"
import AnimationWrapper from "./animationWrapper"

import "./listingTile.css"

const ListingTile = ({ listing, location }) => {

  const listingFile = listing.internal.contentFilePath.split('/').pop();
  const listingSlug = listingFile.slice(0, listingFile.lastIndexOf('.'));
  const listingPath = `/kariera/${listingSlug}`

  return (
    <AnimationWrapper className={`listing-tile-wrapper ${location}-tiles`} animationType="fadeInUp">
        <CustomAniLink Link={listingPath} className="listing-tile">

          <div className="listing-info-wrapper">
            <div className="listing-name">{listing.frontmatter.title}</div>

            {listing.frontmatter.subtitle &&
              <div className="listing-subtitle section-subtitle">
                {listing.frontmatter.subtitle}
              </div>
            }

            {listing.frontmatter.desc_short &&
              <div className="listing-description">
                {listing.frontmatter.desc_short}
              </div>
            }
          </div>

        </CustomAniLink>
    </AnimationWrapper>
  )
}

export default ListingTile